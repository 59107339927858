import React from 'react'
import Link from './localized-link'
import { useLocale } from '../hooks/use-locale'
import { FormattedMessage } from 'react-intl'

export default ({ path, property }) => {
  const locale = useLocale()
  const isRent = path.match(/^\/[a-z]{2}\/let/)
  const isBuy = path.match(/^\/[a-z]{2}\/sale/)
  const isProjects = path.match(/^\/[a-z]{2}\/projects/)

  const itemTitle = property?.attributes.description.title[locale]

  return (
    <div className="c-row c-row--brand">
      <div className="o-container">
        <section className="o-grid">
          <ol
            className="o-grid__item "
            style={{
              listStyle: 'none',
              display: 'flex',
              padding: 0,
              margin: 0,
            }}
          >
            <li className="flex-shrink-0">
              <Link to="/">
                <FormattedMessage id="page.home" />
              </Link>
              <Separator />
            </li>
            {isRent && (
              <li className="flex-shrink-0">
                {property ? (
                  <Link to="/let">
                    <FormattedMessage id="page.to-let" />
                  </Link>
                ) : (
                  <FormattedMessage id="page.to-let" />
                )}
              </li>
            )}
            {isBuy && (
              <li className="flex-shrink-0">
                {property ? (
                  <Link to="/sale">
                    <FormattedMessage id="page.for-sale" />
                  </Link>
                ) : (
                  <FormattedMessage id="page.for-sale" />
                )}
              </li>
            )}
            {isProjects && (
              <li className="flex-shrink-0">
                {property ? (
                  <Link to="/projects">
                    <FormattedMessage id="page.projects" />
                  </Link>
                ) : (
                  <FormattedMessage id="page.projects" />
                )}
              </li>
            )}
            {property?.project && (
              <li className="flex-shrink-0">
                <Separator />
                <Link to={`/projects/${property.project.id}`}>
                  {property.project.attributes.description.title[locale]}
                </Link>
              </li>
            )}
            {property && (
              <li className="truncate">
                <Separator />
                {itemTitle}
              </li>
            )}
          </ol>
        </section>
      </div>
    </div>
  )
}

function Separator() {
  return <span style={{ paddingLeft: 4, paddingRight: 4 }}>/</span>
}
